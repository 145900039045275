import React, { useState, useRef } from "react";
import { graphql } from "gatsby";
import { Builder, builder, BuilderComponent } from "@builder.io/react";
import Link from "../components/Link/Link";
import HeroSlider, { Overlay, Slide, MenuNav } from "hero-slider";
import { useWindowSize } from "../utils/useWindowsize";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import emailjs from "@emailjs/browser";

builder.init(`5d8fedbe34c54b268774c5dc6c49b056`);

function HomePage(props) {
  console.log(props, "props");
  const data = props.data;

  const page = data?.allBuilderModels.landingPage[0].content;

  return (
    <>
      <BuilderComponent renderLink={Link} name="landing-page" content={page} />
    </>
  );
}

export default HomePage;

export const HomePageQuery = graphql`
  query ($path: String!) {
    allBuilderModels {
      landingPage(
        target: { urlPath: $path }
        limit: 1
        options: { cachebust: true }
      ) {
        content
      }
    }
  }
`;

export const HomePageHero = ({ images, height }) => {
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [actualImage, setActualImage] = useState("");

  const size = useWindowSize();
  const getActualImage = (DeskPic, TabPic, MobPic) => {
    if (size[0] <= 850) return MobPic;
    if (size[0] <= 1450) return TabPic;
    return DeskPic;
  };

  const handleSlideClick = (content) => {
    if (typeof window !== "undefined") {
      window.location = content;
    }
  };

  // const authors = images.map(({ heading }) => heading);
  // const quotes = images.map(({ content }) => content);
  const DeskPic = images[carouselIndex - 1]?.DeskImage ?? "";
  const TabPic = images[carouselIndex - 1]?.TabImage ?? "";
  const MobPic = images[carouselIndex - 1]?.MobImage ?? "";

  useEffect(() => {
    // Update the document title using the browser API
    setActualImage(getActualImage(DeskPic, TabPic, MobPic));
  });
  return (
    <HeroSlider
      height={height}
      autoplay
      controller={{
        initialSlide: 0,
        slidingDuration: 100,
        slidingDelay: 0,
        onSliding: (nextSlide) =>
          console.debug("onSliding(nextSlide): ", nextSlide),
        onBeforeSliding: (previousSlide, nextSlide) =>
          console.debug(
            "onBeforeSliding(previousSlide, nextSlide): ",
            previousSlide,
            nextSlide
          ),
        onAfterSliding: (nextSlide) => setCarouselIndex(nextSlide),
      }}
    >
      <Overlay>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexFlow: "column",
            width: "100%",
            height: "100%",
            margin: "0",
            padding: "0",
            pointerEvents: "none",
          }}
        >
          <div
            style={{
              margin: "0 auto",
              padding: "0",
              display: "flex",
              textTransform: "uppercase",
              width: "90%",
              textAlign: "center",
              fontSize: "3.5rem",
            }}
          >
            {/* {authors[carouselIndex - 1]} */}
          </div>
          <div
            style={{
              margin: "24px auto 0",
              padding: "0",
              width: "80%",
              textAlign: "center",
              fontSize: "1.75rem",
            }}
          >
            {/* {quotes[carouselIndex - 1]} */}
          </div>
        </div>
      </Overlay>
      {images?.length > 0 &&
        images.map((image) => {
          // const DeskPic = image?.DeskImage ?? "";
          // const TabPic = image?.TabImage ?? "";
          // const mobPic = image?.MobImage ?? "";

          // const actualImage = getActualImage(DeskPic, TabPic, mobPic);
          const heading = image?.heading ?? "";
          const content = image?.content ?? "";
          return (
            <div
              onClick={() => handleSlideClick(content)}
              style={{ cursor: "pointer" }}
            >
              <Slide
                false
                label={heading}
                background={{
                  backgroundImageSrc: actualImage,
                }}
              />
            </div>
          );
        })}
      <MenuNav />
    </HeroSlider>
  );
};

Builder.registerComponent(HomePageHero, {
  name: "HomePageHero",
  inputs: [
    {
      name: "images",
      type: "list",
      subFields: [
        {
          name: "DeskImage",
          type: "file",
          allowedFileTypes: ["jpeg", "jpg", "png", "svg"],
          required: false,
          defaultValue: "https://i.imgur.com/Gu5Cznz.jpg",
        },
        {
          name: "TabImage",
          type: "file",
          allowedFileTypes: ["jpeg", "jpg", "png", "svg"],
          required: false,
          defaultValue: "https://i.imgur.com/Gu5Cznz.jpg",
        },
        {
          name: "MobImage",
          type: "file",
          allowedFileTypes: ["jpeg", "jpg", "png", "svg"],
          required: false,
          defaultValue: "https://i.imgur.com/Gu5Cznz.jpg",
        },
        { name: "heading", type: "string" },
        { name: "content", type: "string" },
      ],
      defaultValue: [
        {
          heading: "Title",
          content: "content",
          height: "height",
        },
      ],
    },
    { name: "height", type: "string" },
  ],
});

export const ReadMore = ({ defaultText, readMoreText }) => {
  const [text, setText] = useState(defaultText);
  return (
    <div
      onClick={() => setText(text === defaultText ? readMoreText : defaultText)}
      style={{ cursor: "pointer" }}
      dangerouslySetInnerHTML={{ __html: text }}
    ></div>
  );
};
Builder.registerComponent(ReadMore, {
  name: "ReadMore",
  inputs: [
    { name: "defaultText", type: "richText" },
    { name: "readMoreText", type: "richText" },
  ],
});

export const ContactButton = () => {
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      //maxWidth: "500px"
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    form: {
      fontfamily: "Georgia",
      padding: "20px",
      width: "100%",
      maxWidth: "500px",
      background: "#f4f7f8",
    },
    area: {
      width: "100%",
      background: "rgba(255,255,255,.1)",
      border: "none",
      borderRadius: "4px",
      fontSize: "15px",
      outline: "0",
      padding: "10px",
      margin: "1em auto",
      boxSizing: "border-box",
      backgroundColor: "#e8eeef",
      color: "#8a97a0",
    },
    heading: {
      fontSize: "20px",
      fontfamily: `var(--font-md)`,
    },
    submit: {
      color: "#FFF",
      margin: "1em auto",
      background: "#1762A3",
      fontSize: "18px",
      textAlign: "center",
      fontStyle: "normal",
      width: "100%",
      border: "1px solid #16a085",
      marginBottom: "10px",
      padding: "15px",
      cursor: "pointer",
    },
  }));
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm(); // initialise the hook
  const form = useRef();
  const onSubmit = async () => {
    emailjs
      .sendForm(
        "service_44mk54m",
        "template_in6lgsu",
        form.current,
        "7Kldl53BlTVbRv8x6"
      )
      .then(
        (result) => {
          alert("Enquiry Submitted Successfully!");
        },
        (error) => {
          console.log(error.text);
        }
      );

    setOpen(false);
  };
  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        className={classes.modal}
      >
        <form
          ref={form}
          onSubmit={handleSubmit(onSubmit)}
          className={classes.form}
        >
          <p className={classes.heading}>Contact Us!</p>
          <input
            className={classes.area}
            name="name"
            placeholder="Name"
            type="text"
            ref={register({
              required: true,
            })}
          />
          {errors.name &&
            (errors.name.message ? errors.name.message : "name is required.")}
          <input
            className={classes.area}
            name="email"
            placeholder="Email"
            type="email"
            ref={register({
              pattern: {
                message: "Valid email please",
              },
              required: true,
            })}
          />
          <input
            style={{ height: "80px" }}
            className={classes.area}
            name="message"
            placeholder="Message"
            type="text"
            ref={register({
              required: true,
              pattern: {
                message: "Enter a valid name please",
              },
              required: false,
            })}
          />
          {errors.message &&
            (errors.message.message
              ? errors.message.message
              : "message is required.")}
          <input className={classes.submit} type="submit" value="Send" />
        </form>
      </Modal>
      <div style={{ cursor: "pointer" }} onClick={() => setOpen(!open)}>
        Contact Us
      </div>
    </>
  );
};
Builder.registerComponent(ContactButton, {
  name: "ContactButton",
  inputs: [{ name: "defaultText", type: "richText" }],
});
